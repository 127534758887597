import React, { useEffect, useState } from 'react';
import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { debounce } from 'debounce';
import { Form, Formik } from 'formik';
import { DEBOUNCE, PARCEL_FIELDS } from '../../../utils/constants';
import useStyles from './styles';
import ShipmentFromWarehouseFormStep1 from './ShipmentFromWarehouseFormStep1';
import ShipmentFromWarehouseFormStep2 from './ShipmentFromWarehouseFormStep2';
import ShipmentFromWarehouseFormStep3 from './ShipmentFromWarehouseFormStep3';
import { When } from 'react-if';
import SaveIcon from '@material-ui/icons/Save';
import { ArrowLeftIcon } from '@material-ui/pickers/_shared/icons/ArrowLeftIcon';
import clsx from 'clsx';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import {
  AvailableWarehouseProductsItem,
  GetMyProductListQuery,
  GetMyProductListQueryVariables,
  useAddEtsyTrackNumberMutation,
  useCreateWarehouseShipmentOrderMutation,
  useCreateWarehouseShipmentOrderWithoutBuyLabelMutation,
  useGetAvailableWarehouseProductsLazyQuery,
  useGetContactsQuery,
  useGetOrderByIdQuery,
  useGetWarehousesListQuery,
  Warehouse,
  Warehouse_Operations,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { createShipmentFromWarehouseInitValue } from './shipmentFromWarehouseUtils/createShipmentFromWarehouse/shipmentFromWarehouseInitValue';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
  shipmentFromWarehouseSchemas,
  shipmentFromWarehouseWithTaxInformationSchemas,
  shipmentFromWarehouseWithoutLabelSchemas,
} from '../../../utils/validationSchemes';
import { createWarehouseShipmentOrder } from './shipmentFromWarehouseUtils/createWarehouseShipmentOrder';
import { useApolloClient } from '@apollo/client';
import { QUERY_MY_PRODUCT_LIST } from '../../../GraphQL/queries/getMyProductList';
import { useAppFormContext } from '../../../context/FormContext';
import RequestHandler from '../../../components/RequestHandler/RequestHandler';
import { useTranslation } from 'react-i18next';
import { QUERY_WAREHOUSE_SHIPMENT_ORDERS } from '../../../GraphQL/queries/getWarehouseShipmentOrders';
import { TRoutes } from '../../../utils/helpers';
import ShipmentFromWarehouseFormStep4 from './ShipmentFromWarehouseFormStep4';
import { createWarehouseShipmentOrderWithoutBuyLabel } from './shipmentFromWarehouseUtils/createWarehouseShipmentOrderWithoutBuyLabel';
import ShipmentFromWarehouseFormStepTaxInformation from './ShipmentFromWarehouseFormStepTaxInformation';
import ShipmentFromWarehouseFormStep5 from './ShipmentFromWarehouseFormStep5';

const ShipmentFromWarehouseForm = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const createWithLabelRoute = useRouteMatch(
    TRoutes.SHIPMENT_FROM_WAREHOUSE_CREATE_WITH_LABEL,
  );
  const isWithLabel = createWithLabelRoute?.isExact;

  const [warehouseList, setWarehouseList] = useState<Warehouse[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [warehouseId, setWarehouseId] = useState<string>('0');
  const urlSearchParams = new URLSearchParams(location.search);
  const [availableProducts, setAvailableProducts] = useState<
    AvailableWarehouseProductsItem[] | null
  >(null);
  const [errorQuantity, setErrorQuantity] = useState(false);
  const { allCountries } = useAppFormContext();
  const [activeStep, setActiveStep] = useState(0);
  const [updateView, setUpdateView] = useState(0);
  const [isMessage, setIsMessage] = useState<boolean>(false);
  const [isComment, setIsComment] = useState<boolean>(false);
  const [isTaxInformation, setIsTaxInformation] = useState(false);
  const { t } = useTranslation();

  const { data: warehouses, error } = useGetWarehousesListQuery({
    variables: { operation: Warehouse_Operations.Fulfillment },
  });
  const { error: errorContacts, data: contactsData } = useGetContactsQuery();
  const [
    getAvailableWarehouseProductsLazyQuery,
    { data: avaliableProductsData, error: avaliableProductsError },
  ] = useGetAvailableWarehouseProductsLazyQuery();

  const {
    data: orderByIdData,
    error: orderByIdError,
    loading: orderByIdLoading,
  } = useGetOrderByIdQuery({
    skip: !urlSearchParams.get(PARCEL_FIELDS.OTHER.ID),
    variables: { id: Number(urlSearchParams.get(PARCEL_FIELDS.OTHER.ID)) },
  });

  const cachedProducts = client.readQuery<
    GetMyProductListQuery,
    GetMyProductListQueryVariables
  >({
    query: QUERY_MY_PRODUCT_LIST,
  });

  const [
    createWarehouseShipmentOrderMutation,
    { loading: createWarehouseShipmentLoading },
  ] = useCreateWarehouseShipmentOrderMutation({
    refetchQueries: [QUERY_WAREHOUSE_SHIPMENT_ORDERS],
  });

  const [
    createWarehouseShipmentOrderWithoutBuyLabelMutation,
    { loading: createWarehouseShipmentWithoutLabelLoading },
  ] = useCreateWarehouseShipmentOrderWithoutBuyLabelMutation({
    refetchQueries: [QUERY_WAREHOUSE_SHIPMENT_ORDERS],
  });

  const [addEtsyTrackNumberMutation, { loading: addEtsyTrackNumberLoading }] =
    useAddEtsyTrackNumberMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    });

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });
  avaliableProductsError?.message &&
    enqueueSnackbar(avaliableProductsError.message, { variant: 'error' });
  errorContacts?.message &&
    enqueueSnackbar(errorContacts.message, { variant: 'error' });
  orderByIdError?.message &&
    enqueueSnackbar(orderByIdError.message, { variant: 'error' });

  useEffect(() => {
    setUpdateView((prevUpdateView) => {
      return ++prevUpdateView;
    });
  }, [warehouses]);

  useEffect(() => {
    if (!warehouses?.getWarehousesList?.rows) return;
    warehouses?.getWarehousesList?.rows &&
      setWarehouseList(warehouses?.getWarehousesList?.rows as Warehouse[]);
  }, [warehouses]);

  useEffect(() => {
    if (!avaliableProductsData?.getAvailableWarehouseProducts) return;
    avaliableProductsData?.getAvailableWarehouseProducts &&
      setAvailableProducts(
        avaliableProductsData?.getAvailableWarehouseProducts as AvailableWarehouseProductsItem[],
      );
  }, [avaliableProductsData]);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });

  useEffect(() => {
    if (warehouseId === '0') return;
    refetchAvailableWarehouseProduct();
    // eslint-disable-next-line
  }, [warehouseId]);

  const refetchAvailableWarehouseProduct = () => {
    getAvailableWarehouseProductsLazyQuery({
      variables: { warehouseId },
    });
  };

  const getTotalStep = (isCustomInformation: boolean) => {
    let totalStep = 3;
    if (isCustomInformation && isWithLabel) {
      totalStep = 4;
    }
    return totalStep;
  };

  const getIsSubmitButton = (isCustomInformation: boolean) => {
    return isCustomInformation ? activeStep === 4 : activeStep === 3;
  };

  const mutationLoading =
    createWarehouseShipmentLoading ||
    createWarehouseShipmentWithoutLabelLoading;

  return (
    <RequestHandler loading={orderByIdLoading}>
      <Typography variant='h2' className={classes.mainTitle} align='center'>
        {isWithLabel
          ? t('app.shippingWitLabel')
          : t('app.shippingWithoutLabel')}
      </Typography>
      <Formik
        initialValues={createShipmentFromWarehouseInitValue({
          orderByIdData,
          cachedProducts,
          urlSearchParams,
          allCountries,
        })}
        onSubmit={(values: any, helpers) => {
          const isTaxInformation =
            values?.receiver?.country?.iso !== values?.warehouseCountryIso &&
            isWithLabel;
          if (activeStep < getTotalStep(isTaxInformation as boolean)) {
            setActiveStep((prevState) => prevState + 1);
            helpers.setSubmitting(false);
          } else if (isWithLabel) {
            createWarehouseShipmentOrder({
              createWarehouseShipmentOrderMutation,
              values,
              enqueueSnackbar,
              history,
              addEtsyTrackNumberMutation,
              orderByIdData,
            });
          } else {
            createWarehouseShipmentOrderWithoutBuyLabel({
              createWarehouseShipmentOrderWithoutBuyLabelMutation,
              values,
              enqueueSnackbar,
              history,
              orderByIdData,
            });
          }
        }}
        enableReinitialize
        validationSchema={
          isWithLabel
            ? isTaxInformation
              ? shipmentFromWarehouseWithTaxInformationSchemas[activeStep]
              : shipmentFromWarehouseSchemas[activeStep]
            : shipmentFromWarehouseWithoutLabelSchemas[activeStep]
        }
      >
        {({ errors, values, setFieldValue }) => {
          const isTaxInformation =
            values?.receiver?.country?.iso !== values?.warehouseCountryIso &&
            isWithLabel;
          setIsTaxInformation(Boolean(isTaxInformation));
          const setFieldValueDebounce = debounce(setFieldValue, DEBOUNCE);
          return (
            <Form noValidate autoComplete='off'>
              <Stepper orientation='vertical' activeStep={activeStep}>
                <Step>
                  <StepLabel>{t('app.contactInformation')}</StepLabel>
                  <StepContent>
                    <ShipmentFromWarehouseFormStep1
                      warehouseList={warehouseList as Warehouse[]}
                      contactsData={contactsData}
                      updateView={updateView}
                      setUpdateView={setUpdateView}
                      setWarehouseId={setWarehouseId}
                      isWithLabel={isWithLabel}
                    />
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>{t('app.product')}</StepLabel>
                  <StepContent>
                    <ShipmentFromWarehouseFormStep2
                      key={updateView}
                      values={values}
                      currency={values.currency as string}
                      setUpdateView={setUpdateView}
                      setFieldValue={setFieldValue}
                      setFieldValueDebounce={setFieldValueDebounce}
                      products={
                        availableProducts as AvailableWarehouseProductsItem[]
                      }
                    />
                  </StepContent>
                </Step>
                {isTaxInformation ? (
                  <Step>
                    <StepLabel>{t('app.customsInformation')}</StepLabel>
                    <StepContent>
                      <ShipmentFromWarehouseFormStepTaxInformation
                        setFieldValueDebounce={setFieldValueDebounce}
                      />
                    </StepContent>
                  </Step>
                ) : null}
                <Step>
                  <StepLabel>{t('app.additionalOptions')}</StepLabel>
                  <StepContent>
                    <ShipmentFromWarehouseFormStep3
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldValueDebounce={setFieldValueDebounce}
                      isComment={isComment}
                      setIsComment={setIsComment}
                      isMessage={isMessage}
                      setIsMessage={setIsMessage}
                      isWithLabel={isWithLabel}
                    />
                  </StepContent>
                </Step>
                {!isWithLabel ? (
                  <Step>
                    <StepLabel>{t('app.uploadLabel')}</StepLabel>
                    <StepContent>
                      <ShipmentFromWarehouseFormStep4 />
                    </StepContent>
                  </Step>
                ) : (
                  <Step>
                    <StepLabel>{t('app.tariffsAndTerms')}</StepLabel>
                    <StepContent>
                      <ShipmentFromWarehouseFormStep5
                        isLoading={isLoading}
                        setLoading={setLoading}
                      />
                    </StepContent>
                  </Step>
                )}
              </Stepper>

              <div className={classes.actions}>
                <When condition={Boolean(Object.values(errors).length)}>
                  <p className={classes.errorsMessage}>
                    {t('app.checkAllFieldsAbove')}
                  </p>
                </When>

                {/*eslint-disable-next-line */}
                {values.products.map((product: any, index: any) => {
                  if (product.quantity > values?.selected[index]?.maxQuantity) {
                    setErrorQuantity(true);
                    return (
                      <p className={classes.errorsMessage}>
                        {t('app.quantityNotExceedAvailable')}
                      </p>
                    );
                  } else {
                    setErrorQuantity(false);
                  }
                })}

                <Button
                  className={clsx(classes.arrangeAction, classes.action)}
                  variant='contained'
                  type='button'
                  disabled={
                    activeStep === 0 ||
                    isLoading ||
                    addEtsyTrackNumberLoading ||
                    mutationLoading
                  }
                  onClick={() => setActiveStep((prevState) => prevState - 1)}
                  startIcon={<ArrowLeftIcon />}
                >
                  {t('app.back')}
                </Button>

                <Button
                  className={clsx(classes.arrangeAction, classes.action)}
                  type={'submit'}
                  variant='contained'
                  disabled={
                    errorQuantity ||
                    isLoading ||
                    addEtsyTrackNumberLoading ||
                    mutationLoading
                  }
                  startIcon={
                    getIsSubmitButton(isTaxInformation as boolean) ? (
                      <SaveIcon />
                    ) : (
                      <ArrowRightIcon />
                    )
                  }
                  onClick={() => {
                    window.scrollTo(0, 100);
                  }}
                >
                  {getIsSubmitButton(isTaxInformation as boolean)
                    ? t('app.complete')
                    : t('app.next')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </RequestHandler>
  );
};

export default ShipmentFromWarehouseForm;
